<template>
<div class="text_temp">
  <div class="suscripcion">
    <img src="../../src/assets/desuscripcion_3d_manmanchi.png">
    <h3>{{ $t('unsubscribed.txt_se_anulo_la_suscripcion') }}</h3>
    <p>{{ $t('unsubscribed.txt_dejaremos_de_enviarte_promociones') }}</p>
    <ButtonPrimary
        type="button"
        @click="$router.push('/')"
    >
      {{ $t('cobertura.txt_volver_al_inicio') }}
    </ButtonPrimary>
  </div>
</div>
</template>

<script>
import ButtonPrimary from '@/components/new/buttons/ButtonPrimary.vue';
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";

export default {
    name: "Unsubscribed",
    components: { ButtonPrimary },

  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Anular suscripción`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Desuscribirse de promociones en ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  data() {
      return {
        colorPrimary: 'var(--primary)',
        colorPrimary800: 'var(--primary-800)',
        colorComplementary: 'var(--text-black)',
        colorComplementary400: 'var(--complementary-400)'
      }
  }
}
</script>

<style scoped>

.text_temp {
  width: 100%;
  height: 60vh;
  background: var(--complementary-100);
  font-size: 30px;
  margin: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 20px 16px;
}

.suscripcion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  max-width: 500px;
  text-align: center;
  width: max-content;
  height: max-content;
  background-color: var(--text-white);
  border-radius: 16px;
  padding: 30px 10px;
  box-shadow: var(--box-shadow);
}

.suscripcion p{ font-size: 14px; }

@media only screen and (min-width: 425px){
  .suscripcion{ padding: 30px; }
  .suscripcion p{ font-size: 18px; }
}
</style>