<template>
  <div class="container_modal_confirmar">
    <div class="modal_confirm">
      <img
        class="mi_campana"
        src="@/assets/user/icon_campana.svg"
        alt=""
      >
      <p class="tittle_modal_confirm">{{message}}
      </p>
      <p class="opciones_ok_no">
        <span @click="clickOk">{{ok}}</span>
        <span
          class="no"
          @click="$emit('no')"
        >{{no}}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['sendOrder', 'ok', 'no'],
  props: ['ok', 'no', 'message', 'sendOrder'],
  components: {
  },
  methods: {
    clickOk () {
      if (this.sendOrder) {
        this.$emit('sendOrder')
      } else {
        this.$emit('ok')
      }
    }
  }

}
</script>

<style scoped >
.container_modal_confirmar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.47);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  z-index: 999; }
.container_modal_confirmar > .modal_confirm {
  background: white;
  display: flex;
  flex-direction: column;
  border: 0.4em solid #FFFFFF;
  border-radius: 1.5em;
  width: 60%;
  color: #525252;
  font-weight: 500;
  font-size: 1.6em;
  text-align: center;
  position: relative; }
.container_modal_confirmar > .modal_confirm .mi_campana {
  position: absolute;
  right: -20px;
  top: -20px; }
.container_modal_confirmar > .modal_confirm > .tittle_modal_confirm {
  margin-bottom: 0;
  padding: 5%; }
.container_modal_confirmar > .modal_confirm > .opciones_ok_no {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-top: 0.4em solid #FFFFFF;
  margin-bottom: 0; }
.container_modal_confirmar > .modal_confirm > .opciones_ok_no > span {
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: 0.4s ease all; }
.container_modal_confirmar > .modal_confirm > .opciones_ok_no > span:hover {
  color: var(--primary);
  transition: 0.3s ease all; }
.container_modal_confirmar > .modal_confirm > .opciones_ok_no > .no {
  border-right: 0.4em solid #FFFFFF; }
.container_modal_confirmar > .modal_confirm > .opciones_ok_no > span:hover {
  font-weight: bold;
  color: var(--primary); }

@media only screen and (min-width: 768px) {
  .container_modal_confirmar {
    font-size: 12px; }
  .container_modal_confirmar > .modal_confirm {
    width: 50%; } }

@media only screen and (min-width: 992px) {
  .container_modal_confirmar {
    font-size: 13px; }
  .container_modal_confirmar > .modal_confirm {
    width: 45%; } }

@media only screen and (min-width: 1200px) {
  .container_modal_confirmar {
    font-size: 13px; }
  .container_modal_confirmar > .modal_confirm {
    width: 35%; } }

                        
</style>