<template>
  <section class="service">
    <p class="service-back" @click="goBack">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z" fill="currentColor"/>
      </svg>
      {{ $t('cobertura.txt_volver_al_inicio')}}
    </p>
    <div class="service__container section">
      <form class="service__information">
        <h2>{{ $t('customerservice.txt_envia_tu_consulta') }}</h2>
        <p>{{ $t('customerservice.txt_le_daremos_seguimiento') }}</p>
        <div class="service-inputs">
          <InputComponent
              :required=true
              v-model=full_name
              :placeholder="$t('placeholder.txt_nombre_completo')"
              :maxlength="'70'"
              :minlength="'5'"
          />
          <InputComponent
              :required=true
              v-model=id_document
              :placeholder="$t('placeholder.txt_dni')"
              :maxlength="'8'"
              :minlength="'8'"
          />
          <InputComponent
              :required=true
              v-model=phone
              :placeholder="$t('orderinfocomponet.txt_telefo_celular')"
              :maxlength="'9'"
              :minlength="'9'"
          />
          <InputComponent
              :type="'email'"
              name="email_rec"
              :required=true
              :bg_color="'var(--text-white)'"
              :placeholder="$t('createcard.txt_correo_electronico')"
              v-model="email"
          />
          <TextAreaComponent
              :placeholder="'Escribe tu mensaje'"
              :required="true"
              :bgColor="'var(--text-white)'"
              v-model="message"
          />
          <CheckboxComponent
              class="check"
              v-model="terms_checked"
              :required=true
              :id="'cb_comp_apply_job'"
          >
            {{ $t('applyjob.txt_he_leido_y_acepto_los') }}
          </CheckboxComponent>
          <ButtonPrimary
              type="submit"
              :loading="isCustomerService"
              :disabled="isCustomerService"
          >
            {{mensaje_customer_service}}
          </ButtonPrimary>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import {useHead} from "@unhead/vue";
import InputComponent from "@/components/new/inputs/InputComponent";
import {computed, ref} from "vue";
import { useI18n } from 'vue-i18n'
import TextAreaComponent from "@/components/new/inputs/TextAreaComponent";
import CheckboxComponent from "@/components/new/inputs/CheckboxComponent";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";

const route = useRoute();
const router = useRouter();
const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;

const { t } = useI18n()
const full_name = ref('');
const id_document = ref('');
const phone = ref('');
const email = ref('');
const message = ref('');
const terms_checked = ref(false);
const isCustomerService = ref(false);

const mensaje_customer_service = computed(() => {
  if (isCustomerService.value) {
    return t('forgotpassword.txt_cargando_dat')
  } else {
    return t('forgotpassword.txt_enviar')
  }
})

useHead({
  title: "Atención al cliente",
  link: [
    { rel: 'canonical', href: canonicalUrl }
  ],
});

function goBack(){
  router.push('/');
}
</script>

<style scoped>
.service{ background-color: var(--text-white); }

.service-back{
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: var(--text-black);
  font-size: 16px;
  font-weight: var(--semibold);
  cursor: pointer;
  text-decoration: none;
  transition: .3s linear;
  padding-top: 25px;
  padding-left: 16px;
}

.service-back:hover{
  text-decoration: underline;
  color: var(--primary);
}

.service__container{
  max-width: 1400px;
  margin-inline: auto;
  padding-block: 25px;
}

.service__information{
  width: clamp(200px, 100%, 425px);
  margin-inline: auto;
}

.service__information h2{
  text-align: center;
  text-transform: uppercase;
  color: var(--primary);
  font-size: 36px;
}

.service__information p{
  font-size: 18px;
  color: #FD5000;
  text-align: center;
}

.service-inputs{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-block: 25px;
}

.service-inputs .input-container:nth-child(5){
  height: 175px;
}

.check{
  color: var(--primary);
}

@media only screen and (min-width: 1024px){
  .service-back{
    gap: 12px;
    font-size: 22px;
  }

  .service__information h2{
    font-size: 42px;
  }

  .service__information p{
    font-size: 24px;
  }
}
</style>