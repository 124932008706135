import axios from 'axios'

export default {
    async infoUser () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/user`,
        })
    },
    async mailTokenLogin (token) {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/account/mail_token/${token}`
        })
    },
    async deleteAccount () {
        return await axios({
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}/user`,
        })
    },

    async verifyPhone (data = {
        'phone': '',
        'code': '',
        'platform': 'Web'
    }) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/user/verify/phone/`,
            data
        })
    },
    async login (data) {
        return await axios({
            /* headers: {
                 'Content-Type': 'application/ json'
             },*/
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/signin/`,
            data
        })
    },
    async loginSocial (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/account/signin/`,
            data
        })
    },
    async gmailLogin (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/account/signin/gmail/`,
            data
        })
    },
    async registerUser (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/signup`,
            data
        })
    },
    async updateInfoUser (data) {
        return await axios({
            method: 'PUT',
            url: `${process.env.VUE_APP_API}/user`,
            data
        })
    },

    async sendMailPassword (data = {
        "email": ''
    }) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/password/`,
            data
        })
    },

    async resetPassword (data = {
        "token": '',
        "password": '',
        "password2": ''
    }) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/account/password/`,
            data
        })
    },

    async setPassword (data = {
        "old_password": '',
        "new_password": ''
    }) {
        return await axios({
            method: 'PUT',
            url: `${process.env.VUE_APP_API}/user/setpassword/`,
            data
        })
    },

    //ADDRESS
    async deleteAddress (id) {
        return await axios({
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}/user/address/${id}`,
        })
    },
    async addAddress (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/user/address`,
            data
        })
    },
    async listAddress () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/user/address`,
        })
    },
    async modifyAddress (data = {
        name: '',
        longitude: 0.0,
        latitude: 0.0,
        address: '',
        reference: '',
        type_address: ''
    }, id) {
        return await axios({
            method: 'put',
            url: `${process.env.VUE_APP_API}/user/address/${id}`,
            data
        })
    },
    async setAuthHeaders (accessToken) {
        if (accessToken != null) {
            axios.defaults.headers.common.Authorization = "token " + accessToken
        } else {
            axios.defaults.headers.common.Authorization = ''
        }
        axios.defaults.headers.common['platform'] = 'Web'
        axios.defaults.headers.common['device'] = 'Web'
        axios.defaults.headers.common['business'] = process.env.VUE_APP_BUSINESS
    },
    //tarjetas
    async createCard (data = {
        "token": "",
        "card_year": "",
        "card_month": "",
        "local": null
    }) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/card`,
            data
        })
    },
    async deleteCard (card) {
        return await axios({
            method: 'delete',
            url: `${process.env.VUE_APP_API}/card/${card.id}`,

        })
    },
    async listCards () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/card`,
        })
    },

    async updateMarketingPreferences (data) {
        return await axios({
            method: 'PUT',
            url: `${process.env.VUE_APP_API}/user/mkt/mkt_token/`,
            data
        })
    },

}