<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>
        <svg width="27" height="37" viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_767_322" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="37">
            <rect width="27" height="36.3103" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_767_322)">
            <path d="M34.6367 4.77881C32.5156 4.77881 30.4812 5.62149 28.9812 7.12151C25.8582 10.2445 20.7939 10.2445 17.6709 7.12151C14.5479 3.99851 9.4837 3.99851 6.3607 7.12151C3.88419 9.59802 0.188854 10.1089 -2.79309 8.65859V12.3512C0.188854 13.8015 3.88419 13.2906 6.3607 10.8141C9.4837 7.69113 14.5479 7.69113 17.6709 10.8141C20.7939 13.9371 25.8582 13.9371 28.9812 10.8141C30.4812 9.31411 32.5156 8.47143 34.6367 8.47143C35.2796 8.47143 35.9143 8.55009 36.5283 8.69928V5.00666C35.9143 4.85747 35.2796 4.77881 34.6367 4.77881Z" fill="#1000A3"/>
            <path d="M34.6367 12.2371C32.5156 12.2371 30.4812 13.0797 28.9812 14.5798C25.8582 17.7028 20.7939 17.7028 17.6709 14.5798C14.5479 11.4568 9.4837 11.4568 6.3607 14.5798C3.88419 17.0563 0.188854 17.5671 -2.79309 16.1168V19.8095C0.188854 21.2597 3.88419 20.7489 6.3607 18.2724C9.4837 15.1494 14.5479 15.1494 17.6709 18.2724C20.7939 21.3954 25.8582 21.3954 28.9812 18.2724C30.4812 16.7724 32.5156 15.9297 34.6367 15.9297C35.2796 15.9297 35.9143 16.0083 36.5283 16.1575V12.4649C35.9143 12.3157 35.2796 12.2371 34.6367 12.2371Z" fill="#1000A3"/>
            <path d="M34.6367 19.6968C32.5156 19.6968 30.4812 20.5395 28.9812 22.0395C25.8582 25.1625 20.7939 25.1625 17.6709 22.0395C14.5479 18.9165 9.4837 18.9165 6.3607 22.0395C3.88419 24.516 0.188854 25.0268 -2.79309 23.5766V27.2692C0.188854 28.7195 3.88419 28.2086 6.3607 25.7321C9.4837 22.6091 14.5479 22.6091 17.6709 25.7321C20.7939 28.8551 25.8582 28.8551 28.9812 25.7321C30.4812 24.2321 32.5156 23.3894 34.6367 23.3894C35.2796 23.3894 35.9143 23.4681 36.5283 23.6172V19.9246C35.9143 19.7754 35.2796 19.6968 34.6367 19.6968Z" fill="#1000A3"/>
          </g>
        </svg>
        {{category.name}}
      </h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonSecundary>{{ $t('buttons.txt_pedir_ahora') }}</ButtonSecundary>
    </div>
  </article>
</template>

<script>
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary";

export default {
  name: 'CategoriasCarta',
  components: {ButtonSecundary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / .6;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .carta__card h3{
    color: var(--text-white);
    font-family: var(--headings-font-family);
    font-size: 32px;
    text-transform: uppercase;
  }

  .carta__nombre{
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100px;
    opacity: 1;
  }

  .carta__nombre h3{
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: left;
    font-family: var(--headings-font-family);
    font-size: 32px;
    padding: 10px 10px 5px;
    text-transform: uppercase;
    color: var(--primary);
  }

  .carta__hover{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transform: translateY(80%);
    width: 100%;
    height: 100%;
    opacity: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(16, 3, 163, 0.75);
    transition: transform .3s linear;
  }

  .carta__hover:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 100px;
  }

  .carta__card:hover{ flex-direction: column-reverse; }

  .carta__card:hover .carta__nombre{
    top: -100%;
    opacity: 0;
  }

  .carta__card:hover .carta__hover{
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    opacity: 1;
    transform: translateY(0);
  }

  .carta__card:hover .carta__hover:before{
    top: -94px;
    opacity: 1;
  }

  .carta__card:not(:hover) .carta__hover {
    transform: translateY(100%);
    opacity: 1
  }

  .carta__card:not(:hover) .carta__hover:before {
    top: initial;
    opacity: 0;
  }

  @media screen and (min-width: 600px){
    .carta__card{
      width: 48%;
    }
  }

  @media only screen and (min-width: 1024px){
    .carta__card{ width: 425px; }
  }
</style>