<template>
  <section class="about">
    <p class="about-back" @click="goBack">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.825 13L13.425 18.6L12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825Z" fill="currentColor"/>
      </svg>
      {{ $t('cobertura.txt_volver_al_inicio')}}
    </p>
    <div class="about__container section grid">
      <div class="about__image-1"></div>
      <div class="about__image-2">
        <img src="../assets/images-new/logo-gallery.svg" alt="Logo de 200 millas">
        <h2>Nosotros</h2>
        <p>Somos la primera cevichería fast food que nace de nuestro mar peruano, de nuestro 200 millas.</p>
        <br>
        <p>Celebramos el sabor peruano ofreciendo comida rápida y contundente que sorprende y conquista el paladar.</p>
        <br>
        <p>Nuestra propuesta destaca por su espíritu fresco y alegre, honrando en cada plato, nuestra tradición costera.</p>
      </div>
      <div class="about__image-3"></div>
      <div class="about__image-4"></div>
      <div class="about__image-5"></div>
    </div>
  </section>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import {useHead} from "@unhead/vue";

const route = useRoute();
const router = useRouter();
const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;

useHead({
  title: "Sobre nosotros",
  link: [
    { rel: 'canonical', href: canonicalUrl }
  ],
});

function goBack(){
  router.push('/');
}
</script>

<style scoped>
.about{ background-color: #F0DEC14D; }

.about-back{
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: var(--primary);
  font-size: 16px;
  font-weight: var(--semibold);
  cursor: pointer;
  text-decoration: none;
  transition: .3s linear;
  padding-top: 25px;
  padding-left: 16px;
}

.about-back:hover{
  text-decoration: underline;
}

.about__container{
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 50vw 1fr repeat(3, 90vw);
  max-width: 1400px;
  margin-inline: auto;
  padding-block: 25px;
}

[class^="about__image"]{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.about__image-1{
  grid-column: 1 / -1;
  grid-row: 1 / 2;
  background-image: url("../assets/images-new/gallery-1.webp");
}

.about__image-2{
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  background-color: var(--primary);
  color: var(--text-white);
  padding: 10px;
}

.about__image-2 img{
  display: none;
}

.about__image-2 h2{
  font-size: clamp(2.44rem, 2.38vi + 1.85rem, 3.75rem);
  text-transform: uppercase;
  text-align: center;
}

.about__image-2 p{
  font-size: 16px;
  text-align: center;
  font-weight: 100;
}

.about__image-3{
  grid-column: 1 / -1;
  grid-row: 3 / 4;
  background-image: url("../assets/images-new/gallery-2.webp");
}

.about__image-4{
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1 / -1;
  grid-row: 4 / 5;
  background-color: var(--primary);
  background-image: url("../assets/images-new/gallery-3.webp");
}

.about__image-5{
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  background-image: url("../assets/images-new/gallery-4.webp");
}

@media only screen and (min-width: 768px){
  .about__container{
    grid-template-rows: 1fr 35vw;
  }

  .about__image-1{
    grid-column: 3/-1;
    grid-row: 1/2;
    background-position: 90% 0%;
  }

  .about__image-2{
    grid-column: 1/3;
    grid-row: 1/2
  }

  .about__image-3{
    grid-column: 1/3;
    grid-row: 2/3
  }

  .about__image-4{
    grid-column: 3/5;
    grid-row: 2/3
  }

  .about__image-5{
    grid-column: 5/-1;
    grid-row: 2/3;
  }
}

@media only screen and (min-width: 1024px){
  .about__container{
    grid-template-rows: 1fr 30vw;
  }

  .about-back{
    gap: 12px;
    font-size: 22px;
  }

  .about__image-2{
    padding: 60px 30px;
  }

  .about__image-2 img{
    display: block;
    margin-inline: auto;
  }

  .about__image-2 h2{
    text-align: left;
  }

  .about__image-2 p{
    font-size: 18px;
    text-align: left;
  }
}
</style>