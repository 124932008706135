<template>
  <div class="vista_pedidos_tab_2">
    <div class="pedidos_vacios" v-if="this.pedidos.length===0">
      <p>{{ $t('perfilmispedidostab2sp.txt_historial_vacio') }}</p>
    </div>
    <div class="pedidos_cards"
          v-if="!selected_historic_order"
    >
      <ItemOrder
          v-for="(item,index) in pedidos"
          :key="index"
          :item="item"
          mensaje="Ver detalle"
          @viewOrder="showDetail(item)"
          @rateOrder="qualifyOrder(item)"
      ></ItemOrder>
    </div>

      <OrderInfoComponent
          v-if="selected_historic_order"
          :active_order="order_selected"
          :from_tracking=false
          :allow_back=true
          :key="order_selected"
          @close="selected_historic_order=null"
      >
      </OrderInfoComponent>

    <transition name="slide-fade">
      <ModalQualify
          v-if="showModalQualify"
          :order="order"
          @close="showModalQualify=false">
      </ModalQualify>
    </transition>

  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import OrderInfoComponent from "@/components/OrderInfoComponent";
import ModalQualify from "@/components/MODALS/ModalQualify";

export default {
  props: ['orders'],
  components: {
    ModalQualify,
    OrderInfoComponent,
    ItemOrder: defineAsyncComponent(() => import("./PerfilMisPedidosItem")),
  },
  data () {
    return {
      pedidos: [],
      order_selected: [],
      selected_historic_order: false,
      showModalQualify: false,
      order: 0,
    }
  },
  mounted () {
    this.getHistoryOrders()
  },
  methods: {
    qualifyOrder(id) {
      this.order = id
      this.showModalQualify = true
    },
    showDetail (item) {
      this.order_selected = item
      this.selected_historic_order = true
    },
    getHistoryOrders () {
      this.pedidos = this.orders.filter(order => order.status === '4')
    },
  }
}
</script>

<style scoped>
  .pedidos_vacios{
    width: 100%;
    border: 1px dashed;
    border-radius: 8px;
    padding: 20px;
    color: #8F8F8F;
    text-align: center;
    transition: color .3s linear;
  }

  .pedidos_cards{
    display: grid;
    gap: 20px;
  }

  @media only screen and (min-width: 1024px){
    .pedidos_cards{
      justify-items: center;
      height: auto;
      max-height: 800px;
      overflow-y: auto;
      padding: 10px;
    }
  }
</style>