<template>
  <div class="circular-progress">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="40"></circle>
      <circle cx="50" cy="50" r="40" id="pct-ind" :style="circleStyle"></circle>
    </svg>
    <p class="pct">{{ percentage }}</p>
  </div>
</template>

<script setup>
import { computed, toRefs, defineProps } from 'vue';

const props = defineProps({
  percentage: {
    type: Number,
    required: true,
    default: 0
  }
});

const circumference = 2 * Math.PI * 40;

const { percentage } = toRefs(props);

const circleStyle = computed(() => {
  const dashOffset = circumference - (percentage.value / 1000) * circumference;
  return {
    strokeDasharray: `${circumference}px`,
    strokeDashoffset: `${dashOffset}px`,
  };
});
</script>

<style scoped>
.circular-progress {
  position: relative;
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

svg {
  transform: rotate(90deg);
}

circle {
  stroke-width: 5;
  fill: none;
  stroke-linecap: round;
}

circle:nth-of-type(1) {
  stroke: #dee2e6;
}

circle:nth-of-type(2) {
  stroke: var(--primary);
  transition: stroke-dashoffset 0.3s ease;
}

.pct {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 42px;
  font-weight: bold;
  font-family: var(--headings-font-family);
}
</style>
