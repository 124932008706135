<template>
  <section class="modal__bg" @click="closeModal">
    <div class="modal__container" @click.stop>
      <svg class="icon-close-modal" @click="closeModalClicked" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor"/></svg>
      <svg class="type-icon" v-if="isSuccessModal" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0072 0.509766C27.6705 0.509766 35.5073 8.34653 35.5073 18.0099C35.5073 27.6732 27.6705 35.51 18.0072 35.51C8.34384 35.51 0.50708 27.6732 0.50708 18.0099C0.50708 8.34653 8.34384 0.509766 18.0072 0.509766ZM14.3731 23.694L10.0886 19.406C9.3587 18.6757 9.35854 17.4847 10.0886 16.7545C10.8188 16.0244 12.0151 16.029 12.7401 16.7545L15.7606 19.7774L23.2746 12.2633C24.0048 11.5331 25.196 11.5331 25.926 12.2633C26.6563 12.9934 26.6552 14.1856 25.926 14.9148L17.0841 23.7566C16.355 24.4858 15.1628 24.4869 14.4327 23.7566C14.4122 23.7361 14.3924 23.7153 14.3731 23.694Z" fill="#00BA00"/>
      </svg>
      <svg class="type-icon" v-if="!isSuccessModal" width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17.5" fill="#F2DFE4"/>
        <path d="M18 22C18.2833 22 18.5208 21.9042 18.7125 21.7125C18.9042 21.5208 19 21.2833 19 21C19 20.7167 18.9042 20.4792 18.7125 20.2875C18.5208 20.0958 18.2833 20 18 20C17.7167 20 17.4792 20.0958 17.2875 20.2875C17.0958 20.4792 17 20.7167 17 21C17 21.2833 17.0958 21.5208 17.2875 21.7125C17.4792 21.9042 17.7167 22 18 22ZM17 18H19V12H17V18ZM18 27C16.6167 27 15.3167 26.7375 14.1 26.2125C12.8833 25.6875 11.825 24.975 10.925 24.075C10.025 23.175 9.3125 22.1167 8.7875 20.9C8.2625 19.6833 8 18.3833 8 17C8 15.6167 8.2625 14.3167 8.7875 13.1C9.3125 11.8833 10.025 10.825 10.925 9.925C11.825 9.025 12.8833 8.3125 14.1 7.7875C15.3167 7.2625 16.6167 7 18 7C19.3833 7 20.6833 7.2625 21.9 7.7875C23.1167 8.3125 24.175 9.025 25.075 9.925C25.975 10.825 26.6875 11.8833 27.2125 13.1C27.7375 14.3167 28 15.6167 28 17C28 18.3833 27.7375 19.6833 27.2125 20.9C26.6875 22.1167 25.975 23.175 25.075 24.075C24.175 24.975 23.1167 25.6875 21.9 26.2125C20.6833 26.7375 19.3833 27 18 27ZM18 25C20.2333 25 22.125 24.225 23.675 22.675C25.225 21.125 26 19.2333 26 17C26 14.7667 25.225 12.875 23.675 11.325C22.125 9.775 20.2333 9 18 9C15.7667 9 13.875 9.775 12.325 11.325C10.775 12.875 10 14.7667 10 17C10 19.2333 10.775 21.125 12.325 22.675C13.875 24.225 15.7667 25 18 25Z" fill="#D9184B"/>
      </svg>
      <h2>{{ title }}</h2>
      <p v-html="content"></p>
      <ButtonPrimary
          @click="closeModal"
          type="button"
      >
        {{ $t('buttons.txt_aceptar') }}
      </ButtonPrimary>
    </div>
  </section>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
const props = defineProps(['isSuccessModal', 'title', 'content'])

const emit = defineEmits(['close_modal_validate_data']);

function closeModalClicked() {
  if (props.isSuccessModal) {
    return
  }
  closeModal()
}

function closeModal () {
  emit('close_modal_validate_data');
}
</script>

<style scoped>
.modal__container{
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--text-white);
  box-shadow: 0 0 8px rgba(41, 41, 41, .2);
  border-radius: 16px;
  padding: 20px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  width: clamp(200px, 100%, 400px);
}

h2{
  margin-bottom: 0;
  font-size: 24px;
  text-align: center;
  color: var(--text-black);
  font-weight: var(--medium);
  font-family: var(--headings-font-family);
  text-transform: uppercase;
}

p{
  color: var(--text-black);
  font-weight: var(--regular);
  text-align: center;
}

.type-icon{
  display: block;
  min-width: 48px;
  min-height: 48px;
  margin-inline: auto;
}

.icon-close-modal{
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
  margin-left: auto;
  scale: 1;
  color: var(--text-black);
  transition: all .3s linear;
}

.icon-close-modal:hover{
  color: var(--primary);
  scale: 1.5;
}
</style>