import axios from 'axios'

export default {

    async getRecentCoupon () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/coupon/current/`
        })
    },
    async listaDeCupones () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/coupons`
        })
    },
    async activarUnCupon (id = 1) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/coupon/${id}`
        })
    },
    async listaDeMisCupones () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/coupon`
        })
    },
    async validarCupon (coupon, product_ids, type_order, local_id, new_version) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/coupon/validate`,
            data: {
                coupon, product_ids, type_order, local_id, new_version
            }
        })
    },
    async obtenerInfoDelUsuario () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/user/points/`,
        })
    }
}
