import { createStore } from 'vuex';
import db from '../firebase';

const store = createStore({
    state: {
        database: db,
        showModalLoginRegister: false,
        showShoppingCart: false,
        goToPayClicked: false,
        user: {},
        isAuthenticated: false,
        orderDirection: {
            objectDirection: "",
            direction: "",
            reference: "",
            district: "",
            type_direction: ""
        },
        orderLocal: null,
        listLocals: [],
        typeOrder: 3,
        shoppingCartInfo: [],
        webVersion: 0,
        diasDeLaSemana: [
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            "Domingo"
        ],
        isSelfService: false,
        businessInfo: null,
    },
    mutations: {
        setTypeOrder(state, data) {
            state.typeOrder = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('typeOrder', JSON.stringify(data));
            }
        },
        setShoppingCartInfo(state, data) {
            state.shoppingCartInfo = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('shoppingCartInfo', JSON.stringify(data));
            }
        },
        pushShoppingCartProduct(state, data) {
            state.shoppingCartInfo.push(data);
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('shoppingCartInfo', JSON.stringify(state.shoppingCartInfo));
            }
        },
        setUser(state, data) {
            state.user = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('user', JSON.stringify(data));
            }
        },
        setOrderDirection(state, data) {
            state.orderDirection = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('orderDirection', JSON.stringify(data));
            }
        },
        setOrderLocal(state, data) {
            state.orderLocal = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('orderLocal', JSON.stringify(data));
            }
        },
        setListLocals(state, data) {
            state.listLocals = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('listLocals', JSON.stringify(data));
            }
        },
        setGoToPayClicked(state, data) {
            state.goToPayClicked = data;
        },
        setIsSelfService(state, data) {
            state.isSelfService = data;
        },
        setShowShoppingCart(state, data) {
            state.showShoppingCart = data;
        },
        setAuthenticatedTrue(state) {
            state.isAuthenticated = true;
        },
        setAuthenticatedFalse(state) {
            state.isAuthenticated = false;
        },
        clearProducts(state) {
            state.shoppingCartInfo = [];
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('shoppingCartInfo', JSON.stringify(state.shoppingCartInfo));
            }
        },
        setShowModalLoginRegister(state, value) {
            state.showModalLoginRegister = value;
        },
        setWebVersion(state, data) {
            state.webVersion = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('webVersion', JSON.stringify(data));
            }
        },
        setBusinessInfo(state, data) {
            state.businessInfo = data;
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('businessInfo', JSON.stringify(data));
            }
        },
    }
});

export default store;
