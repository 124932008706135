import axios from 'axios'

export default {


    async getCurrentShoppingCart () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/order/user/shopping_cart/`
        })
    },

    async createShoppingCartItem (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/order/user/shopping_cart/`,
            data
        })
    },

    async updateShoppingCartItem (data) {
        return await axios({
            method: 'put',
            url: `${process.env.VUE_APP_API}/order/user/shopping_cart/`,
            data
        })
    },

    async deleteShoppingCartItem (data) {
        return await axios({
            method: 'delete',
            url: `${process.env.VUE_APP_API}/order/user/shopping_cart/`,
            data
        })
    },

    async purchaseOrder (data) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/order/shopping_cart/buy/`,
            data
        })
    },

    async purchaseOrderWithFile (data) {
        return await axios({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'post',
            url: `${process.env.VUE_APP_API}/order/shopping_cart/buy/`,
            data
        })
    },

    async updateTypeDeliveryShoppingCart (data) {
        return await axios({
            method: 'put',
            url: `${process.env.VUE_APP_API}/order/shopping_cart/change/`,
            data
        })
    },

    async deleteAllShoppingCartByChangingLocal () {
        return await axios({
            method: 'delete',
            url: `${process.env.VUE_APP_API}/order/shopping_cart/change/`
        })
    },

    async uploadOrderComments (data) {
        return await axios({
            method: 'put',
            url: `${process.env.VUE_APP_API}/order/rating/`,
            data
        })
    },

    async getOrderByQr (qr_code) {
        return await axios({
            params: {
                qr_code
            },
            method: 'get',
            url: `${process.env.VUE_APP_API}/order/qr/`
        })
    },

    async listOfOrders () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/order`,
        })
    },
    async recentOrder () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/order/recent`,
        })
    },
    async detailOrder (data = { order: '' }) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/order/detail`,
            data
        })
    },
    async detailOrderWithQr (data = { order: '' }) {
        return await axios({
            method: 'post',
            url: `${process.env.VUE_APP_API}/order/detail/qr`,
            data
        })
    }
}