import { defineAsyncComponent } from 'vue';
import 'vue-datepicker-next/locale/es';

export default {
    install(app) {
        if (typeof window !== 'undefined') {
            // Registrar el componente DatePicker globalmente
            const DatePicker = defineAsyncComponent(() =>
                import('vue-datepicker-next')
            );
            const {GMapMap, GMapMarker, GMapAutocomplete, GMapPolygon} = defineAsyncComponent(() =>
                import ('@fawmi/vue-google-maps')
            );
            app.component('DatePicker', DatePicker);
            app.component('GMapMap', GMapMap);
            app.component('GMapMarker', GMapMarker);
            app.component('GMapAutocomplete', GMapAutocomplete);
            app.component('GMapPolygon', GMapPolygon);

        }
    }
};
