import axios from 'axios'

export default {
    async getBusinessInfo () {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/business/info/`,
        })
    },
    async getPlacesByCoords (lat, lng) {
        return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat.toFixed(6)},${lng.toFixed(6)}&key=${process.env.VUE_APP_GOOGLE_GEOCODING_API_KEY}`)
    },
    async sendComplain(data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/complaints_book/new/`,
            data
        })
    },
    async applyJobs (data) {
        return await axios({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            method: 'POST',
            url: `${process.env.VUE_APP_API}/jobs/apply/`,
            data
        })
    },

    async checkWebVersion (data) {
        return await axios({
            method: 'POST',
            url: `${process.env.VUE_APP_API}/check/`,
            data
        })
    }
}
