<template>
  <div class="carousel">
    <div class="carousel_sponsored_container" :style="isDragging? 'cursor: grabbing': ''"
         @mousedown="startDrag"
         @touchstart="startDrag"
         @mousemove="handleDrag"
         @touchmove="handleDrag"
         @mouseup="endDrag"
         @touchend="endDrag">
      <div class="carousel__main">
        <div class="carousel__item"
              v-for="(item, index) in info_carousel"
              :key="index"
        >
          <div class="carousel__information desktop" :style="`background-image: url(${item.imgDesktop});`">
            <div class="box-shadow">
              <h1 class="carousel__title">{{item.title}}</h1>
              <p class="carousel__paragraph">{{item.paragraph}}</p>
            </div>
<!--             <div class="image__container" :style="`background-image: url(${item.imgDesktopText})`">
              <ButtonPrimary
                  type="button"
                  @click="$emit('banner_buy')"
              >
                COMPRAR
              </ButtonPrimary>
            </div>-->
          </div>
          <div class="carousel__information mobile" :style="`background-image: url(${item.imgMobile});`">
            <div class="box-shadow">
              <h1 class="carousel__title">{{item.title}}</h1>
              <p class="carousel__paragraph">{{item.paragraph}}</p>
            </div>
            <!-- <div class="image__container" :style="`background-image: url(${item.imgMobileText})`">
              <ButtonPrimary
                  type="button"
                  @click="$emit('banner_buy')"
              >
                COMPRAR
              </ButtonPrimary>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="carousel-dots">
      <span
          class="dot"
          v-for="(item, index) in info_carousel"
          :key="index"
          :class="{ active: index === current_index }"
          @click="goToSlide(index)"
      ></span>
    </div>
  </div>

</template>

<script>
// import ButtonPrimary from './new/buttons/ButtonPrimary.vue';

export default {
  name: "CarouselRastreo",
  props: ["info_carousel"],
  emits: ['banner_buy'],
  data() {
    return {
      clicked_carousel: false,
      current_index: 0,
      carousel_items: null,
      carouselIntervalId: null,
      isDragging: false,
      startX: 0,
      deltaX: 0,
      initialIndex: 0,
      refresh_key: 0,
    };
  },
  async mounted() {
    this.carousel_items = document.querySelectorAll(".carousel__item");
    await this.$nextTick();
    setTimeout(() => {
      this.carouselIntervalId = setInterval(() => {
        if (!this.clicked_carousel) {
          this.nextSlide(); //
        }
      }, 6000);
    }, 500);
  },
  beforeUnmount() {
    if (this.carouselIntervalId !== null) {
      clearInterval(this.carouselIntervalId);
    }
  },
  methods: {
    startDrag(event) {
      this.clicked_carousel = true
      this.refresh_key += 1
      this.isDragging = true;
      this.startX = event.type === "touchstart" ? event.touches[0].clientX : event.clientX;
      this.initialIndex = this.current_index; // Guarda el índice inicial
    },
    handleDrag(event) {
      if (this.isDragging) {
        this.clicked_carousel = true
        this.refresh_key += 1
        const clientX = event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
        this.deltaX = clientX - this.startX;
        // Ajusta la velocidad del deslizamiento según tus preferencias
        const slideWidth = this.carousel_items[this.current_index].offsetWidth + this.getMarginWidth();
        this.deltaX = this.deltaX / slideWidth * 100; // Convierte el desplazamiento en porcentaje
        this.updateCarousel();
      }
    },
    endDrag() {
      if (this.isDragging) {
        this.isDragging = false;
        if (Math.abs(this.deltaX) > 10) {
          if (this.deltaX > 0) {
            this.current_index = this.initialIndex - 1;
          }
          else {
            this.current_index = this.initialIndex + 1;
          }
          if (this.current_index < 0) {
            // Si el índice es menor que 0, vuelve al último índice
            this.current_index = this.carousel_items.length - 1;
          }
          else if (this.current_index >= this.carousel_items.length) {
            // Si el índice es mayor o igual al número de diapositivas, vuelve al primer índice
            this.current_index = 0;
          }
          this.clicked_carousel = true;
          this.refresh_key += 1
          this.updateCarousel();
        }
        this.deltaX = 0;
      }
    },
    goToSlide(index) {
      // Método para ir a una diapositiva específica
      this.clicked_carousel = true
      this.current_index = index;
      this.updateCarousel();
    },
    updateCarousel() {
      if (this.clicked_carousel) {
        let current_value = this.refresh_key
        setTimeout(() => {
          if (current_value === this.refresh_key) {
            this.clicked_carousel = false;
          }
        }, 8000);
      }
      let carousel_categories = null;
      let itemWidth = null;
      let offset = null;
      carousel_categories = document.querySelector(".carousel__main");
      itemWidth = this.carousel_items[this.current_index].offsetWidth + this.getMarginWidth();
      offset = -this.current_index * itemWidth;
      if (carousel_categories !== null) {
        carousel_categories.style.transform = `translateX(${offset}px)`;
      }
    },
    getMarginWidth() {
      let firstItem = this.carousel_items[0];
      const styles = window.getComputedStyle(firstItem);
      const marginLeft = parseFloat(styles.marginLeft);
      const marginRight = parseFloat(styles.marginRight);
      return marginLeft + marginRight;
    },
    nextSlide() {
      this.current_index++;
      if (this.current_index >= this.carousel_items.length) {
        this.current_index = 0;
      }
      this.updateCarousel();
    },
  },
  // components: { ButtonPrimary }
}
</script>

<style scoped>

em{
  color: var(--primary);
  font-style: normal;
}

cite{
  font-style: normal;
}

/* TESTIMONIALS */
.carousel{
  position: relative;
  background: black;
  height: 350px;
}

.carousel_sponsored_container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;
}

.carousel__main{
  height: 100%;
  display: flex;
  transition: 0.7s ease all;
}

.carousel__item{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  transition: 0.7s ease all;
  min-width: 100%;
  max-width: 100%;
  color: var(--text-white);
  background-color: black;
}

.carousel__information{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  background-position-x: 10%;
  background-size: cover;
}

.box-shadow{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);
}

.carousel__title{
  text-align: center;
  font-size: 36px;
}

.carousel__paragraph{
  width: 300px;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
}

.carousel__information button{
  background-color: transparent;
  border: 1px solid var(--text-white);
  color: var(--text-white);
}

.image__container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 250px;
  padding-top: 40px;
  margin-top: auto;
  border-radius: 50% 50% 25% 25% / 50% 50% 0% 0%;
  background-color: rgba(0, 0 ,0, .6);
  background-repeat: no-repeat;
  background-size: 600px;
  background-position: center 100%;
}

.img{
  display: block;
}

.carousel-dots {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  background: var(--text-white);
  cursor: pointer;
  transition: background 0.3s;
}

.dot.active {
  background: var(--complementary-500);
  transform: scale(1.5);
}

.desktop{
  display: none;
}

.mobile{
  display: flex;
}

@media only screen and (min-width: 390px){
  .carousel__information{ background-position-x: 20%; }
}

@media only screen and (min-width: 425px){
  .carousel{ height: 400px; }
}

@media only screen and (min-width: 480px){
  .carousel{ height: 500px; }
}

@media only screen and (min-width: 600px){
  .carousel{ height: 600px; }
}

@media only screen and (min-width: 768px){
  .carousel{
    min-width: 200px;
    height: 80vh;
    background-color: var(--text-white);
    border-radius: 16px;
    overflow: hidden;
  }
  .desktop{ display: flex;}
  .mobile{ display: none;}
  .carousel__information{
    justify-content: flex-end;
    background-position: center;
  }
  .carousel__item button{ margin-left: 250px; }
  .carousel__item:first-child button{ margin-left: 125px; }
  .image__container{
    width: 550px;
    height: 400px;
    padding-top: 120px;
    border-radius: 100% 0% 0% 100% / 100% 0% 100% 0% ;
    background-size: 800px;
    background-position: 100% 140%;
  }
  .carousel-dots {
    width: 100px;
    position: absolute;
    bottom: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
  }

  .carousel__title,
  .carousel__paragraph{
    width: 180px;
  }
}

@media only screen and (min-width: 1024px){
  .carousel{
    min-width: 300px;
    max-width: 300px;
  }
  .carousel__title,
  .carousel__paragraph{
    width: 250px;
  }
}

@media only screen and (min-width: 1280px){
  .carousel{
    min-width: 350px;
    min-width: 350px;
  }
  .carousel__paragraph{ width: 300px; }
}
</style>