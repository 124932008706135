import axios from 'axios'

export default {

    async getLocalAvailability (local_id) {
        return await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API}/local/${local_id}/validate`,
        })
    },
    async getLocalInformation (id) {
        return await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API}/local/${id}/info`,

        })
    },
    async getPriceDelivery (id, lat, lon, type) {
        return await axios({
            params: {
                lat,
                lon,
                type
            },
            method: "GET",
            url: `${process.env.VUE_APP_API}/local/${id}/check`,

        })
    },
    async getListLocalsWithSchedules () {
        return await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API}/local/schedules/all/`,
        })
    },
    async listLocalesCercanos (lat, lon) {
        return await axios({
            params: {
                lat,
                lon
            },
            method: "GET",
            url: `${process.env.VUE_APP_API}/local`,

        })
    },
    async listLocalesTodos () {
        return await axios({
            method: "GET",
            url: `${process.env.VUE_APP_API}/local/raw`,

        })
    },
    async listCategoriesOfLocal (idLocal) {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/category/`,
            params: {
                local: idLocal
            }
        })
    },
    async listAllProductsFromLocal (type_order, local_id) {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/products/user/local/${local_id}`,
            params: {
                type_order: type_order
            }
        })
    },
    async singleProductFullInfo (type_order, product_id) {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/products/user/info/${product_id}`,
            params: {
                type_order: type_order
            }
        })
    },
    async listOfAditionalItems (local_id) {
        return await axios({
            method: 'GET',
            url: `${process.env.VUE_APP_API}/additional_items/${local_id}`,
        })
    },
    async typesOfCards () {
        return await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}/local/card`,
        })
    }
}