<template>
  <div
    class="container_lista_orders"
    v-if="orderList.length>1 && order_selected == null"
  >
    <ItemOrder
      v-for="(item,index) in orderList"
      :key="index"
      :item="item"
      mensaje="Ver pedido"
      @viewOrder="showDetail(item)"
    ></ItemOrder>
  </div>
    <OrderInfoComponent
        v-if="order_selected"
        :active_order="order_selected"
        :from_tracking=false
        :key="order_selected"
        :allow_back="orderList.length > 1"
        @close="order_selected=null"
    >
    </OrderInfoComponent>
  <PerfilMisPedidosTab1SinPedidos v-if="orderList.length === 0">
  </PerfilMisPedidosTab1SinPedidos>
</template>
<script>
import {defineAsyncComponent} from "vue";
import PerfilMisPedidosTab1SinPedidos from "@/views/Perfil/PerfilMisPedidosTab1SinPedidos";
import OrderInfoComponent from "@/components/OrderInfoComponent";
export default {
  props: ['orders'],
  components: {
    OrderInfoComponent,
    PerfilMisPedidosTab1SinPedidos,
    ItemOrder: defineAsyncComponent(() => import("./PerfilMisPedidosItem")),
  },
  data () {
    return {
      orderList: [],
      order_selected: null,
    }
  },
  mounted () {
    this.loadOrdersAndFilter()
  },
  methods: {
    showDetail (item) {
      this.order_selected = item
    },
    loadOrdersAndFilter () {
        this.orderList = this.orders.filter(order => { if (order.status !== '3' && order.status !== '4') { return order } })
      if (this.orderList.length === 1) {
        this.order_selected = this.orderList[0]
      }
    }
  }
}
</script>
<style scoped>
  .container_lista_orders{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    height: auto;
    max-height: 800px;
    overflow-y: auto;
    padding: 10px;
  }
</style>