<template>

  <div class="spinner_small"
       v-if="type==='small'"
       :style="{
    'border-top': '3px solid ' + spinnerColor,
    'border-right': '3px solid ' + spinnerColor,
    'border-bottom': '3px solid ' + spinnerColor
  }">
  </div>

  <div class="spinner_medium"
       v-if="type==='medium'"
       :style="{
    'border-top': '3px solid ' + spinnerColor,
    'border-right': '3px solid ' + spinnerColor,
    'border-bottom': '3px solid ' + spinnerColor
  }">
  </div>


  <div class="spinner_large"
       v-if="type==='large' || type===undefined"
       :style="{
    'border-top': '4px solid ' + spinnerColor,
    'border-right': '4px solid ' + spinnerColor,
    'border-bottom': '4px solid ' + spinnerColor
  }">
  </div>
</template>

<script>

export default {
  name: "SpinnerComponent",
  props: ['spinnerColor', 'type'],
  components: {
  },
};
</script>

<style>

.spinner_small {
  border: 3px solid #3498db;
  border-left-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}


.spinner_medium {
  border: 3px solid #3498db;
  border-left-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

.spinner_large {
  border: 4px solid #3498db;
  border-left-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

</style>