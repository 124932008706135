<template>
  <article class="modal__bg" @click="closeModal">
    <section class="confirmationmodal__wrapper" @click.stop>
      <svg @click="closeModal" class="icon-close-modal" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-0218f0b3="" d="M12 13.4L7.10005 18.3C6.91672 18.4834 6.68338 18.575 6.40005 18.575C6.11672 18.575 5.88338 18.4834 5.70005 18.3C5.51672 18.1167 5.42505 17.8834 5.42505 17.6C5.42505 17.3167 5.51672 17.0834 5.70005 16.9L10.6 12L5.70005 7.10005C5.51672 6.91672 5.42505 6.68338 5.42505 6.40005C5.42505 6.11672 5.51672 5.88338 5.70005 5.70005C5.88338 5.51672 6.11672 5.42505 6.40005 5.42505C6.68338 5.42505 6.91672 5.51672 7.10005 5.70005L12 10.6L16.9 5.70005C17.0834 5.51672 17.3167 5.42505 17.6 5.42505C17.8834 5.42505 18.1167 5.51672 18.3 5.70005C18.4834 5.88338 18.575 6.11672 18.575 6.40005C18.575 6.68338 18.4834 6.91672 18.3 7.10005L13.4 12L18.3 16.9C18.4834 17.0834 18.575 17.3167 18.575 17.6C18.575 17.8834 18.4834 18.1167 18.3 18.3C18.1167 18.4834 17.8834 18.575 17.6 18.575C17.3167 18.575 17.0834 18.4834 16.9 18.3L12 13.4Z" fill="currentColor"></path></svg>
      <h2>¡Datos enviados correctamente!</h2>
      <p>Gracias por enviar su reclamo. Nos pondremos en contacto con usted pronto.</p>
      <ButtonPrimary type="button" @click="closeModal">Cerrar</ButtonPrimary>
    </section>
  </article>
</template>

<script setup>
import { defineEmits } from "vue"
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";

const emits = defineEmits(['close_modal_confirmation']);

function closeModal(){
  emits('close_modal_confirmation');
}
</script>

<style scoped>
  .confirmationmodal__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: clamp(100px, 100%, 625px);
    max-height: 85%;
    overflow-y: auto;
    background-color: var(--text-white);
    box-shadow: var(--box-shadow);
    border-radius: 16px;
    padding: 20px 10px;
  }

  svg{
    display: block;
    min-width: 24px;
    min-height: 24px;
    cursor: pointer;
    margin-left: auto;
    scale: 1;
    color: var(--text-black);
    transition: all .3s linear;
  }

  svg:hover{
    color: var(--primary);
    scale: 1.5;
  }

  h2{ font-size: 24px; }

  p{
    font-size: 14px;
    text-align: center;
  }

  @media only screen and (min-width: 768px){
    .confirmationmodal__wrapper{ padding: 30px 60px; }

    p{ font-size: 16px; }
  }
</style>