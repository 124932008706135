<template>
  <div class="sin_pedido">
    <p>{{ $t('perfilmispedidostab1sp.txt_conoce_la_carta_personaliza') }}</p>
    <ButtonPrimary
        type="button"
        @click="$emit('close');this.$router.push({name: 'menudigital'})"
    >
      {{ $t('buttons.txt_comenzar_ahora') }}
    </ButtonPrimary>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";

export default {
  components: {ButtonPrimary}

}
</script>

<style scoped>
  .sin_pedido{
    width: 80%;
    border: 1px dashed;
    border-radius: 8px;
    padding: 20px;
    color: #8F8F8F;
    text-align: center;
    cursor: pointer;
    transition: color .3s linear;
    margin-inline: auto;
  }

  .sin_pedido:hover{
    color: var(--primary);
  }

  .sin_pedido button{
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
  }
</style>