import buildApp from "./app";

import Vue3NativeNotification from "vue3-native-notification";
import VueGoogleMaps from '@fawmi/vue-google-maps';
import globalComponents from "@/plugins/global-components";

const { app, router, store } = buildApp();

// eslint-disable-next-line no-underscore-dangle
const storeInitialState = window.INITIAL_DATA;
if (storeInitialState) {
    store.replaceState(storeInitialState);
}

router.isReady().then(() => {
    app.use(Vue3NativeNotification, {
        requestOnNotify: true,
    });
  app.use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      libraries: 'places'
    },
  });
    app.use(globalComponents)
    // app.use(head)
    app.mount("#app", true);
});
