<template>
  <div
      class="filter-chip"
      v-for="(item, index) in options_filterChips"
      :key="`dark_radio_filter_chips__${name}_${index}`"
  >
    <input
        type="checkbox"
        :value="item"
        :name="name"
        :id="`dark_radio_filter_chips__${name}__${index}`"
        style="display: none"
        :checked="modelValue.includes(item)"
        @change="onChanged"
    >
    <label class="chip-label" :for="`dark_radio_filter_chips__${name}__${index}`">
      {{ item }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "DarkSelectFilterChip",

  emits: ['update:modelValue', 'filterChanged'],

  props: ['options_filterChips', 'modelValue', 'name', 'valueObj'],

  setup(props, { emit }) {

    const onChanged = (event) => {
      const value = event.target.value;
      const isChecked = event.target.checked;
      const updatedModelValue = [...props.modelValue];

      if (isChecked) {
        updatedModelValue.push(value);
      } else {
        const index = updatedModelValue.indexOf(value);
        if (index !== -1) {
          updatedModelValue.splice(index, 1);
        }
      }
      emit("update:modelValue", updatedModelValue);
      emit('filterChanged')
    };

    return { onChanged };
  },
})
</script>

<style scoped>
</style>