<template>
  <div class="perfil_notificaciones">
    <p class="beneficios_description">{{ $t('perfilmisgestionnotification.txt_gestiona_tus_notificaciones') }}</p>
    <div class="list__notificaciones">
      <div class="item__notificaciones">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.66666 23.3333C4.02499 23.3333 3.47569 23.1049 3.01874 22.6479C2.5618 22.191 2.33333 21.6417 2.33333 21V7C2.33333 6.35833 2.5618 5.80903 3.01874 5.35208C3.47569 4.89514 4.02499 4.66667 4.66666 4.66667H23.3333C23.975 4.66667 24.5243 4.89514 24.9812 5.35208C25.4382 5.80903 25.6667 6.35833 25.6667 7V21C25.6667 21.6417 25.4382 22.191 24.9812 22.6479C24.5243 23.1049 23.975 23.3333 23.3333 23.3333H4.66666ZM23.3333 9.33333L14.6125 14.7875C14.5153 14.8458 14.4132 14.8896 14.3062 14.9188C14.1993 14.9479 14.0972 14.9625 14 14.9625C13.9028 14.9625 13.8007 14.9479 13.6937 14.9188C13.5868 14.8896 13.4847 14.8458 13.3875 14.7875L4.66666 9.33333V21H23.3333V9.33333ZM14 12.8333L23.3333 7H4.66666L14 12.8333ZM4.66666 9.625V7.90417V7.93333V7.91875V9.625Z" fill="currentColor"/>
        </svg>
        <p>{{ $t('perfilmisgestionnotification.txt_correos_con_promociones_exclusivas') }}</p>
        <SwitchComponent
            :id="'checkbox_notification_gmail'"
            v-model="is_subscribed_mkt"/>
      </div>
      <div class="item__notificaciones">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.3 16.352C19.474 15.9145 18.648 15.4735 17.815 15.0465C17.57 14.9205 17.36 14.987 17.1815 15.2145C16.9435 15.5225 16.6845 15.813 16.422 16.1C16.0195 16.5445 15.8585 16.569 15.33 16.2995C13.7935 15.526 12.5895 14.4025 11.7425 12.901C11.515 12.4985 11.522 12.348 11.8125 11.9945C12.019 11.7425 12.271 11.522 12.439 11.2455C12.5475 11.067 12.621 10.7905 12.5615 10.6085C12.3095 9.828 12.019 9.0615 11.711 8.302C11.6375 8.1235 11.473 7.945 11.305 7.847C10.8605 7.581 10.1185 7.658 9.73 8.001C8.82 8.799 8.414 9.807 8.5435 11.0145C8.6555 12.0715 9.177 12.957 9.7265 13.839C11.2735 16.317 13.342 18.144 16.1595 19.04C16.625 19.187 17.115 19.2745 17.591 19.39L17.6085 19.3515C17.836 19.32 18.074 19.32 18.2945 19.257C19.817 18.8125 20.3595 18.151 20.489 16.912C20.51 16.6985 20.5345 16.478 20.3035 16.3555L20.3 16.352Z" fill="currentColor"/>
          <path d="M25.6445 17.6085C25.606 17.717 25.5605 17.822 25.5185 17.927C25.5605 17.822 25.6025 17.7135 25.6445 17.6085Z" fill="#DBDBDB"/>
          <path d="M22.2425 18.662C22.3055 18.5675 22.365 18.4765 22.4245 18.382C22.365 18.4765 22.3055 18.571 22.2425 18.662Z" fill="#DBDBDB"/>
          <path d="M22.2425 18.662C22.2425 18.662 22.2215 18.6935 22.2075 18.711C22.2145 18.7005 22.225 18.69 22.232 18.676L22.2425 18.662Z" fill="currentColor"/>
          <path d="M5.06799 20.685C5.21149 20.874 5.36199 21.056 5.51599 21.2345C5.36199 21.056 5.21149 20.8705 5.06799 20.685Z" fill="currentColor"/>
          <path d="M4.592 20.013C4.7215 20.209 4.8545 20.398 4.9945 20.587C4.8545 20.4015 4.7215 20.209 4.592 20.013Z" fill="currentColor"/>
          <path d="M8.008 20.1635C8.008 20.1635 7.99051 20.1495 7.98351 20.139C7.99051 20.146 8.001 20.153 8.008 20.1635Z" fill="currentColor"/>
          <path d="M8.1795 23.534C7.973 23.4045 7.77 23.2645 7.5705 23.121C7.77 23.2645 7.973 23.401 8.1795 23.534Z" fill="currentColor"/>
          <path d="M10.8675 22.0675C10.8675 22.0675 10.8465 22.0605 10.836 22.0535C10.8465 22.057 10.857 22.0605 10.8675 22.0675Z" fill="currentColor"/>
          <path d="M4.11951 19.2395C4.24901 19.474 4.38551 19.698 4.53251 19.922C4.38901 19.698 4.24901 19.4705 4.11951 19.2395Z" fill="currentColor"/>
          <path d="M24.6785 7.5985C24.57 5.733 23.247 4.1755 21.4235 3.766C21.336 3.3355 20.9545 3.01 20.4995 3.01C20.0445 3.01 19.663 3.3355 19.5755 3.766C17.752 4.1755 16.429 5.733 16.3205 7.5985C15.939 7.721 15.6625 8.0745 15.6625 8.4945C15.6625 9.0125 16.086 9.436 16.604 9.436H24.3915C24.9095 9.436 25.333 9.0125 25.333 8.4945C25.333 8.0745 25.0565 7.7175 24.675 7.5985H24.6785ZM22.785 7.553H18.214C18.3575 6.419 19.327 5.5405 20.4995 5.5405C21.672 5.5405 22.6415 6.419 22.785 7.553Z" fill="currentColor"/>
          <path d="M25.641 11.2385C25.4905 10.591 24.7975 10.2235 24.1745 10.4615L24.1605 10.4685C23.471 10.731 23.086 11.452 23.2225 12.173C23.408 13.1425 23.443 14.161 23.296 15.211C22.6975 19.53 19.082 22.9005 14.7315 23.2015C12.5755 23.352 10.5595 22.7605 8.91101 21.6615C8.86551 21.6545 8.82001 21.6405 8.77101 21.6405C8.68001 21.6405 8.58551 21.6545 8.48751 21.6825C7.69651 21.896 6.90201 22.1025 6.10751 22.3125L5.77501 22.4L5.64551 22.435L6.14601 20.5975C6.23001 20.286 6.31051 19.978 6.40501 19.67C6.45051 19.516 6.45751 19.376 6.43301 19.2395C5.24301 17.535 4.60251 15.421 4.78451 13.1495C5.14501 8.631 8.81301 4.9735 13.335 4.6235C14.1155 4.564 14.8785 4.599 15.6135 4.7215C16.0965 4.802 16.576 4.578 16.807 4.1475L16.821 4.1195C17.255 3.311 16.772 2.31 15.869 2.17C13.8845 1.8655 11.7355 2.0545 9.55501 2.8805C6.38751 4.0775 3.92701 6.6745 2.85951 9.8875C1.61701 13.6325 2.20501 17.248 3.94451 20.0865C3.85701 19.943 3.76601 19.7995 3.68201 19.6525C3.69601 19.775 3.69251 19.8975 3.66801 19.9955C3.48251 20.706 3.28301 21.4165 3.08701 22.1235L2.81751 23.107C2.67401 23.632 2.53401 24.1535 2.38701 24.689L2.04401 25.956L2.62501 25.844C2.70201 25.83 2.76851 25.816 2.83501 25.802L4.23851 25.4345C5.54751 25.0915 6.85301 24.7485 8.16551 24.416C8.26351 24.3915 8.39301 24.388 8.51901 24.3985C10.5 25.452 12.8135 25.963 15.2565 25.725C20.657 25.1965 25.067 20.9685 25.823 15.596C26.0365 14.0805 25.956 12.614 25.634 11.2385H25.641Z" fill="currentColor"/>
        </svg>
        <p>{{ $t('perfilmisgestionnotification.txt_whatsapp_de_estado_de_pedido') }}</p>
        <SwitchComponent
            :id="'checkbox_notification_ws_orders'"
            v-model="has_whatsapp_order_status"/>
      </div>
      <div class="item__notificaciones">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.597 3.675C23.2715 3.3495 22.743 3.3495 22.4175 3.675L18.06 8.0325C17.9025 8.19 17.815 8.4 17.815 8.6205C17.815 8.841 17.9025 9.051 18.06 9.2085C18.2175 9.366 18.4275 9.4535 18.648 9.4535C18.8685 9.4535 19.0785 9.366 19.236 9.2085L23.5935 4.851C23.919 4.5255 23.919 3.997 23.5935 3.6715L23.597 3.675Z" fill="currentColor"/>
          <path d="M18.921 5.73651C19.6385 5.73651 20.223 5.15201 20.223 4.43451C20.223 3.71701 19.6385 3.13251 18.921 3.13251C18.2035 3.13251 17.619 3.71701 17.619 4.43451C17.619 5.15201 18.2035 5.73651 18.921 5.73651Z" fill="currentColor"/>
          <path d="M23.009 6.9615C22.2915 6.9615 21.707 7.546 21.707 8.2635C21.707 8.981 22.2915 9.5655 23.009 9.5655C23.7265 9.5655 24.3075 8.981 24.3075 8.2635C24.3075 7.546 23.723 6.9615 23.009 6.9615Z" fill="currentColor"/>
          <path d="M24.675 17.941C24.6365 18.0425 24.5945 18.144 24.556 18.2455C24.598 18.144 24.6365 18.0425 24.675 17.941Z" fill="#DBDBDB"/>
          <path d="M21.413 18.9525C21.413 18.9525 21.392 18.984 21.3815 18.998C21.3885 18.9875 21.399 18.977 21.406 18.9665L21.413 18.9525Z" fill="currentColor"/>
          <path d="M25.641 11.2385C25.4905 10.591 24.7975 10.2235 24.1745 10.4615L24.1605 10.4685C23.471 10.731 23.086 11.452 23.2225 12.173C23.408 13.1425 23.443 14.161 23.296 15.211C22.6975 19.53 19.082 22.9005 14.7315 23.2015C12.5755 23.352 10.5595 22.7605 8.91101 21.6615C8.86551 21.6545 8.82001 21.6405 8.77101 21.6405C8.68001 21.6405 8.58551 21.6545 8.48751 21.6825C7.69651 21.896 6.90201 22.1025 6.10751 22.3125L5.77501 22.4L5.64551 22.435L6.14601 20.5975C6.23001 20.286 6.31051 19.978 6.40501 19.67C6.45051 19.516 6.45751 19.376 6.43301 19.2395C5.24301 17.535 4.60251 15.421 4.78451 13.1495C5.14501 8.63099 8.81301 4.97349 13.335 4.62349C14.1155 4.56399 14.8785 4.59899 15.6135 4.72149C16.0965 4.80199 16.576 4.57799 16.807 4.14749L16.821 4.11949C17.255 3.31099 16.772 2.30999 15.869 2.16999C13.8845 1.86549 11.7355 2.05449 9.55501 2.88049C6.38751 4.07749 3.92701 6.67449 2.85951 9.88749C1.61701 13.6325 2.20501 17.248 3.94451 20.0865C3.85701 19.943 3.76601 19.7995 3.68201 19.6525C3.69601 19.775 3.69251 19.8975 3.66801 19.9955C3.48251 20.706 3.28301 21.4165 3.08701 22.1235L2.81751 23.107C2.67401 23.632 2.53401 24.1535 2.38701 24.689L2.04401 25.956L2.62501 25.844C2.70201 25.83 2.76851 25.816 2.83501 25.802L4.23851 25.4345C5.54751 25.0915 6.85301 24.7485 8.16551 24.416C8.26351 24.3915 8.39301 24.388 8.51901 24.3985C10.5 25.452 12.8135 25.963 15.2565 25.725C20.657 25.1965 25.067 20.9685 25.823 15.596C26.0365 14.0805 25.956 12.614 25.634 11.2385H25.641Z" fill="currentColor"/>
          <path d="M20.3 16.352C19.474 15.9145 18.648 15.4735 17.815 15.0465C17.57 14.9205 17.36 14.987 17.1815 15.2145C16.9435 15.5225 16.6845 15.813 16.422 16.1C16.0195 16.5445 15.8585 16.569 15.33 16.2995C13.7935 15.526 12.5895 14.4025 11.7425 12.901C11.515 12.4985 11.522 12.348 11.8125 11.9945C12.019 11.7425 12.271 11.522 12.439 11.2455C12.5475 11.067 12.621 10.7905 12.5615 10.6085C12.3095 9.82799 12.019 9.06149 11.711 8.30199C11.6375 8.12349 11.473 7.94499 11.305 7.84699C10.8605 7.58099 10.1185 7.65799 9.73 8.00099C8.82 8.79899 8.414 9.80699 8.5435 11.0145C8.6555 12.0715 9.177 12.957 9.7265 13.839C11.2735 16.317 13.342 18.144 16.1595 19.04C16.625 19.187 17.115 19.2745 17.591 19.39L17.6085 19.3515C17.836 19.32 18.074 19.32 18.2945 19.257C19.817 18.8125 20.3595 18.151 20.489 16.912C20.51 16.6985 20.5345 16.478 20.3035 16.3555L20.3 16.352Z" fill="currentColor"/>
        </svg>
        <p>{{ $t('perfilmisgestionnotification.txt_whatsapp_promocionales') }}</p>
        <SwitchComponent
            :id="'checkbox_notification_ws_promotion'"
            v-model="is_subscribed_whatsapp"/>
      </div>
      <ButtonPrimary
        @click="saveChanges"
        :loading="loading">
        Guardar cambios
      </ButtonPrimary>
    </div>
  </div>
</template>

<script setup>

import SwitchComponent from "@/components/new/inputs/SwitchComponent.vue";
import {getCurrentInstance, ref} from "vue"
import { computed } from 'vue';
import { useStore } from 'vuex';
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import UserServices from "@/services/user-services";
const {appContext} = getCurrentInstance();

const store = useStore();
const user = computed(() => store.state.user);
const toast = appContext.config.globalProperties.$toast;

let is_subscribed_mkt = ref(user.value.is_subscribed_mkt);
let has_whatsapp_order_status = ref(user.value.has_whatsapp_order_status);
let is_subscribed_whatsapp = ref(user.value.is_subscribed_whatsapp);
let loading = ref(false);

async function saveChanges() {
  loading.value = true;
  let response = await UserServices.updateMarketingPreferences({
    is_subscribed_mkt: is_subscribed_mkt.value,
    has_whatsapp_order_status: has_whatsapp_order_status.value,
    is_subscribed_whatsapp: is_subscribed_whatsapp.value
  })
  if (response.data.success) {
    store.commit('setUser', response.data.data);
    toast.success('Información actualizada con éxito.')
  } else {
    toast.error(response.data.message)
  }
  loading.value = false
}

</script>

<style scoped>
  .list__notificaciones{
    display: grid;
    gap: 20px;
    width: clamp(280px, 100%, 425px);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .item__notificaciones{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    box-shadow: 0px 0px 8px rgba(41, 41, 41, .2);
    border-radius: 8px;
    padding: 10px;
    background-color: transparent;
    transition: background-color .3s linear;
  }

  .item__notificaciones p{ font-size: 14px; }

  .switchBtn{ margin-left: auto; }

  @media only screen and (min-width: 768px){

    .list__notificaciones{ width: clamp(280px, 100%, 500px); }

    .item__notificaciones{ padding: 20px 16px; }

    .item__notificaciones p{ font-size: 18px; }
  }
</style>