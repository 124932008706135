<template>
  <div class="perfil_mi_cuenta">
    <p class="perfil__description">{{ $t('perfil.txt_en_esta_seccion_encontaras') }}</p>
    <div class="content_perfil_mi_cuenta">
      <div class="super_content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
  .perfil__description{
    color: var(--text-black);
    font-size: 18px;
  }
</style>