<template>
  <section class="paymentscreenws__wrapper">
    <h1>{{ $t('whatsAppPaymentScreen.txt_pago_en_linea') }}</h1>
    <h2>{{ $t('whatsAppPaymentScreen.txt_resumen_del_pedido') }}</h2>
    <section class="paymentscreenws__dishs">
      <div class="table__responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('paymentscreen.txt_producto') }}</th>
              <th>{{ $t('paymentscreen.txt_cantidad') }}</th>
              <th>{{ $t('paymentscreen.txt_total') }}</th>
            </tr>
          </thead>
          <ItemProductoPagar
              v-for="(item,index) in shoppingCartInfo"
              :key="index"
              :item="item"
              :index="index"
          >
          </ItemProductoPagar>
        </table>
      </div>
      <div class="total">
        <p>{{ $t('modalmicarrito.txt_subtotal') }}</p>
        <span>S/{{ subTotalProductos.toFixed(2) }}</span>
        <p v-if="typeOrder === 3">{{ $t('paymentscreen.txt_costo_de_envio') }}</p>
        <span v-if="loading_delivery_cost && typeOrder === 3">{{ $t('forgotpassword.txt_cargando_dat') }}</span>
        <span v-if="!loading_delivery_cost && typeOrder === 3">S/{{ price_delivery.toFixed(2) }}</span>
        <p>Total:</p>
        <span>S/{{ totalFake.toFixed(2) }}</span>
      </div>
      <ButtonPrimary class="btn_pagar" @click="showModalPrePagarTarjeta = true">
        {{ $t('paymentscreen.txt_pagar') }}
      </ButtonPrimary>
    </section>
    <div class="mobile__component" @click="openShoppingCartModal" :class="{ 'mobile__component-active': shoppingCartInfo.length > 0}">
      <p>{{ $t('modalmicarrito.txt_total_double_dot') }} S/{{ totalFake.toFixed(2) }}</p>
      <ButtonPrimary @click="showModalPrePagarTarjeta = true">
        {{ $t('paymentscreen.txt_pagar') }}
      </ButtonPrimary>
    </div>
  </section>
  <transition name="slide-fade">
    <ConfirmPay
        :tipoOrden="typeOrder"
        :is_niubiz="payload.type_payment === 0"
        v-if="showModalPrePagarTarjeta"
        ref="PrePayment"
        @cancel="showModalPrePagarTarjeta = false"
        @close="showModalPrePagarTarjeta = false"
        @successNiubiz="prepareShowModal"
        @termsAccepted="sendOrder"
    />
  </transition>
  <transition name="slide-fade">
    <PaymentIziPay
        v-if="showModalIzipayForm"
        :formToken = "formToken"
        :payload="payload"
        :clearProducts="clearProducts"
        @paymentAccepted="successIziPay"
        @closeIziForm="showModalIzipayForm=false"
    />
  </transition>
</template>

<script setup>
import { useStore } from 'vuex';
import {onMounted, ref, computed, defineAsyncComponent} from 'vue';
import ItemProductoPagar from "@/components/ItemProductoPagar";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import ConfirmPay from "@/components/MODALS/ConfirmPay";
import payment from "@/services/payment-services";

let formToken = ref("");
let shopping_cart_initial_length = ref(0);
let price_delivery = ref(0);
let payload = ref({
  amount_pay_user: null,
  comments: "",
  coupon: "",
  change_time: false,
  razon_social: "",
  ruc: "",
  time_to_order: null,
  card_type_pos: null,
  type_payment: null,
  invoice_type: -1
})
let datos = ref({
  applyCupon: false,
  cupon: "",
  amount_discount: 0,
  type_discount: 0,
})
let product_ids = ref([]);
let loading = ref(false);
let loading_delivery_cost = ref(true);
let showModalPrePagarTarjeta = ref(false);
let showModalIzipayForm = ref(false);
let PaymentIziPay = defineAsyncComponent(() => import("@/components/MODALS/PaymentIziPay.vue"));

const store = useStore();

const shoppingCartInfo = computed(() => store.state.shoppingCartInfo);
const typeOrder = computed(() => store.state.typeOrder);
const clearProducts = computed( () => store.state.clearProducts);
// const orderLocal = computed(() => store.state.orderLocal);

onMounted(async () => {
  getProductIdsArray();
});

//   ↓ Falta corregir ↓
/*function commission() {
  let numProd = 0;
  for (let i = 0; i < shoppingCartInfo.value.length; i++) {
    numProd = numProd + parseInt(shoppingCartInfo.value[i].cant);
  }
  if (orderLocal.value.is_available_commission) {
    return numProd * parseFloat(orderLocal.value.commission);
  } else {
    return 0;
  }
}*/

const subTotalProductos = computed(() => {
  let suma = 0;
  shoppingCartInfo.value.forEach((element) => {
    let price = element.price;
    price += element.extras;
    price -= element.item_discount;
    price = price * element.cant;
    suma += price;
  });
  return suma;
});

const totalFake = computed(() => {
  let precioFinalTemp = subTotalProductos.value;
  switch (typeOrder.value) {
    case 3:
      precioFinalTemp = precioFinalTemp + parseFloat(price_delivery.value);
      break;
      //   ↓ Falta corregir ↓
/*    default:
      precioFinalTemp = precioFinalTemp + commission.value;
      break;*/
  }
  if (precioFinalTemp < 0) {
    precioFinalTemp = 0;
  }
  return precioFinalTemp;
})

function getProductIdsArray() {
  product_ids.value = [];
  shoppingCartInfo.value.forEach((product) => {
    product_ids.value.push(product.product);
  });
  shopping_cart_initial_length.value = product_ids.value.length;
}

function successIziPay() {
  datos.value.applyCupon = false
  clearProducts.value();
  showModalIzipayForm.value = false
}

async function prepareShowModal() {
  showModalPrePagarTarjeta.value = false;
  loading.value = true
  let res = await payment.createPayment(payload.value);
  if (res.data.success) {
    loading.value = false
    formToken.value = res.data.data
    showModalIzipayForm.value = true;
  } else {
    loading.value = false
    this.$toast.error(res.data.message);
  }
}

async function sendOrder(){
  showModalPrePagarTarjeta.value = false;
}

</script>

<style scoped>
  .paymentscreenws__wrapper{
    padding: 25px 0;
    width: clamp(200px, 100%, 800px);
    margin-inline: auto;
  }

  h1{
    font-family: var(--headings-font-family);
    text-align: center;
    font-size: 1.25rem;
    padding-inline: 16px;
  }

  h2{
    font-size: 1rem;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: var(--medium);
    padding-inline: 16px;
  }

  .table__responsive{
    border-radius: 0;
  }

  thead{
    display: none;
  }

  .total{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: var(--complementary-100);
    padding: 20px 16px;
  }

  .total p{
    font-size: 16px;
    font-weight: var(--medium);
  }

  .total span{
    margin-left: auto;
    font-weight: var(--medium);
  }

  .btn_pagar{
    display: none;
  }

  .mobile__component{
    display: none;
    cursor: pointer;
  }

  .mobile__component-active{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 5px 16px;
    z-index: 5;
    background-color: white;
    box-shadow: 0 0 8px rgba(41, 41, 41, .2)
  }

  .mobile__component p{ font-size: 18px; }

  .mobile__component button{
    width: 120px;
  }

  @media only screen and (min-width: 768px){
    .mobile__component{ display: none; }

    .btn_pagar{
      display: block;
      margin-inline: auto;
      width: 300px;
      height: 40px;
    }
  }

  @media only screen and (min-width: 1024px){
    thead{
      display: table-header-group;
      border-bottom: 1px solid var(--complementary-400);
    }

    thead th{
      font-size: 18px;
      padding: 20px;
      white-space: nowrap;
      text-align: left;
      line-height: 1.5;
      font-weight: var(--extrabold);
    }
  }

</style>