<template>
  <div class="container_modal_confirmar" @click="$emit('no')">
    <div class="modal_confirm" @click.stop>
      <img
          class="mi_campana"
          src="@/assets/user/icon_campana.svg"
          alt=""
      >
      <p class="tittle_modal_confirm">{{ $t('modalAddCouponProduct.txt_deseas_usar_tu_cupon') }} {{title_coupon}} {{ $t('modalAddCouponProduct.txt_en_este_pedido') }}
      </p>
      <p class="opciones_ok_no">
        <span
            class="no"
            @click="$emit('no')"
        >{{ $t('dropdownaddress.txt_no') }}</span>
        <span @click="clickOk">{{ $t('dropdownaddress.txt_si') }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalAddCouponProduct",
  emits: ['no', 'productAdded'],
  props: ['title_coupon'],
  components: {
  },
  methods: {
    clickOk () {
      this.$emit('productAdded')
    }
  }

}
</script>

<style scoped lang="sass" >
.container_modal_confirmar
  position: fixed
  top: 0px
  left: 0px
  width: 100%
  height: 100vh
  background: rgba(0,0 ,0 ,0.47 )
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-size: 10px
  z-index: 999
  >.modal_confirm
    background: white
    display: flex
    flex-direction: column
    border: 0.4em solid #FFFFFF
    border-radius: 1.5em
    width: 60%
    color: #525252
    font-weight: 500
    font-size: 1.6em
    text-align: center
    position: relative
    .mi_campana
      position: absolute
      right: -20px
      top: -20px
    >.tittle_modal_confirm
      margin-bottom: 0
      padding: 5%
    >.opciones_ok_no
      display: flex
      flex-direction: row
      justify-content: space-around
      align-items: center
      border-top: 0.4em solid #FFFFFF
      margin-bottom: 0
      >span
        width: 50%
        text-align: center
        cursor: pointer
        transition: 0.4s ease all
      >.no
        border-right: solid
      >span:hover
        font-weight: bold
        color: var(--primary)

@media only screen and (min-width: 768px)
  .container_modal_confirmar
    font-size: 12px
    >.modal_confirm
      width: 50%
@media only screen and (min-width: 992px)
  .container_modal_confirmar
    font-size: 13px
    >.modal_confirm
      width: 45%
@media only screen and (min-width: 1200px)
  .container_modal_confirmar
    font-size: 13px
    >.modal_confirm
      width: 35%

</style>